import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/devops.jpg'

const pageTitle = "DevOps";
const pageSlug = "devops";


class DevOps extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">DevOps helps you simplify the distribution of your applications, enabling you to process and deploy efficiently, all with the advantages of the cloud.</h6>
        </div>
</section>

<section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>Today, companies use DevOps to deliver value more efficiently and simplify the delivery of their code to production processes. Giving them a major competitive advantage.</h3>
                  <span className="break"></span>
                  <p>Creating a DevOps approach that emphasizes teamwork, interaction, and instant feedback is one of the quickest and most powerful ways of creating value. DevOps strategies work to simplify the application delivery process in production to name a few methods automated testing, agile design, continuous integration, and continuous delivery.</p>
                  <p>Using DevOps while applying the concepts, methods, and strategies for operations and development throughout your entire delivery process will yield increased growth, performance increases, team efficiency, and reliability while achieving business value more quickly. Give your organization a powerful tool for gaining a competitive edge.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h5>Our DevOps approach </h5>
                  <p>Our experts will first evaluate the internal capabilities, technologies available, and business needs.</p>
                  <h5>Our DevOps Capabilities</h5>
                  <p>Implementing DevOps can be a daunting task because you need to shift your focus; that's why we give our clients:</p>
                  <ul>
                    <li>Transformation planning</li>
                    <li>Process design</li>
                    <li>Architecture alignment to incorporate old models into new developments</li>
                    <li>Build and deployment automation</li>
                    <li>Governance</li>
                    <li>Readiness assessments</li>
                    <li>DevOps tool selection and implementation</li>
                    <li>Reliable testing services</li>
                  </ul>
                  <h5>Related Expertise</h5>
                  <p>Our IT experts can also provide:</p>
                  <ul>
                    <li>Agile Services</li>
                    <li>Continuous improvements coaching</li>
                    <li>Application Lifecycle Management Services</li>
                    <li>Long term support</li>
                    <li>Software Quality Assurance and Testing</li>
                    <li>Cloud Services</li>
                    <li>Makes delivering automation faster and easier</li>
                    <li>Change Management</li>
                    <li>DevOps general coaching</li>
                  </ul>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <hr />
                  <h3>Learn more about what DevOps can do for you?</h3>
                  <p><a href="">Discover more</a></p>



                    
                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default DevOps
